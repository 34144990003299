import React, { useState, useEffect } from "react";
import { 
    FormGroup, 
    FormControl, 
    ControlLabel, 
    PageHeader, 
    Glyphicon, 
    Checkbox, 
    Button 
} from "react-bootstrap";
import { View } from "react-native";
import { Fade } from "react-awesome-reveal";
import { 
    cancelStripePayment, 
    postStripeCustomer, 
    postStripePayment,
    getStripePayment,
    resetStripeReader
} from "../utils/bedrockAPI";
import {
    addDaysToDate,
    getUser,
} from "../utils/Common";
import { Checkmark } from "react-checkmark";
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import "./reserve.css";
import DateFnsUtils from "@date-io/date-fns";
import styled from 'styled-components';

const BUTTON_DEFAULT_TEXT = "Please Complete the Form";

const CustomDateTimePicker = styled(DatePicker)`
    & .MuiInputBase-input {
        font-size: 24px;
        border: 1px solid #CCC;
        padding: 5px;
        cursor: pointer;
        text-align: center;
        min-width: 433px;
        min-height: 40px;
        border-radius: 6px;
    }

    & .MuiInputBase-input:hover {
        background-color: #F5F5F5;
    }
`;

export default function Reservations(props) {
    // const [firstName, setFirstName] = useState("");
    // const [lastName, setLastName] = useState("");
    const [fullName, setFullName] = useState("");
    const [companyName, setCompanyName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [emailActivated, setEmailActivated] = useState(false);
    const [checkedIsChecked, setCheckedIsChecked] = useState(false);
    const [buttonText, setButtonText] = useState(BUTTON_DEFAULT_TEXT);
    const [cancellable, setCancellable] = useState(false);
    const [stripePayment,setStripePayment] = useState(null);
    const [renderComplete, setRenderComplete] = useState(false);
    const [ignoreDateSelection, setIgnareDateSelection] = useState(false);
    const tempDate = addDaysToDate(new Date(),14);
    const [dateSelected, setDateSelected] = useState(tempDate);

    const delay = ms => new Promise(res => setTimeout(res,ms));

    useEffect(() => {
        if(
            fullName.length>0 &&
            companyName.length>0 &&
            email.length>0 &&
            phone.length>0 &&
            checkedIsChecked
        ) {
            setButtonText("Click to Provide Your Credit Card")
        }
    },[fullName,companyName,email,phone,checkedIsChecked]);

    useEffect(() => {
        if(buttonText==="Payment Complete") {
            setTimeout(() => {
                setRenderComplete(true);
            },2000);
        }
    },[buttonText]);

    useEffect(() => {
        if(renderComplete) {
            setTimeout(() => {
                setRenderComplete(false);
                window.location.reload(false);
            },2500);
        }
    },[renderComplete]);

    async function cancelPayment(e) {
        if(stripePayment) {
            try {
                const cancellationRequest = {
                    paymentIntentID: stripePayment.id,
                    terminalID: process.env.REACT_APP_STRIPE_PAYMENT_READER_ID
                }
                console.log(cancellationRequest);
                const cancelPayment = await cancelStripePayment(cancellationRequest);
            } catch(error) {
                console.log(error);
                showError("Unable to cancel -- Contact a Bedrock Rep");
            }
        } 
        // else {
        //     showError("Nothing to cancel");
        // }
    }

    function showError(msg) {
        props.setErrorStatus("error-message");
        props.setErrorMessage(msg);
        props.setShowError(true);
        setTimeout(function() {props.setShowError(false);},5000);
    }

    async function handleSubmitForPayment(e) {
        e.preventDefault();
        console.log("Submitting for Payment");
        setButtonText("Creating Customer...");
        let meta = {selectedDate:null};
        if(!ignoreDateSelection) {
            if(dateSelected)
                meta.selectedDate = dateSelected.toDateString();
        }

        const stripeCustomer = {
            fullName: fullName,
            // firstName: firstName,
            // lastName: lastName,
            companyName: companyName,
            email: email,
            phone: phone,
            metadata: meta,
        }
        let stripeCustomerResponse;
        try {
            stripeCustomerResponse = await postStripeCustomer(stripeCustomer);
            // console.log(stripeCustomerResponse);
        } catch(error) {
            console.log(error);
            setButtonText(BUTTON_DEFAULT_TEXT);
            showError("Unable to create customer -- Are you connected to the Network?");
        }
        setCancellable(true);
        setButtonText("Requesting Payment from Card Reader...");
        const stripePaymentRequest = {
            amount: 1.00,
            readerID: process.env.REACT_APP_STRIPE_PAYMENT_READER_ID,
            customerID: stripeCustomerResponse.id,
            description: "This $1.00 fee reserves a free month of CAP service with Bedrock Wireles",
            customerEmail: stripeCustomerResponse.email,
        }
        let paymentError = false;
        let errorMessage = "Is the Card Reader On?";
        let alreadyCancelled = true;
        let stripePaymentResponse = null;
        try {
            stripePaymentResponse = await postStripePayment(stripePaymentRequest);
            setStripePayment(stripePaymentResponse);
            alreadyCancelled = false;
            while(true) {
                if(stripePaymentResponse) {
                    await delay(2000);
                    stripePaymentResponse = await getStripePayment(stripePaymentResponse);
                    setStripePayment(stripePaymentResponse);
                    if(stripePaymentResponse.status === "canceled") {
                        errorMessage = "Stripe Payment Cancelled";
                        alreadyCancelled = true;
                        throw new Error(errorMessage);
                    } else if(stripePaymentResponse.status === "succeeded") {
                        setButtonText("Payment Complete");
                        setCancellable(false);
                        break;
                    }
                } else {
                    errorMessage = "Stripe Payment Could Not Be Found"
                    throw new Error(errorMessage);
                }
            }
        } catch(error) {
            console.log(error);
            setButtonText(BUTTON_DEFAULT_TEXT);
            showError("Unable to process payment - canceling... "+errorMessage);
            paymentError = true;
        }
        if(paymentError) {
            try {
                if(!alreadyCancelled) {
                    const cancellationRequest = {
                        paymentIntentID: stripePaymentResponse.id,
                        terminalID: process.env.REACT_APP_STRIPE_PAYMENT_READER_ID
                    }
                    const cancelledPayment = await cancelStripePayment(cancellationRequest);
                    showError("Payment Has Been Cancelled");
                }
                await delay(5000);
                const resetReader = await resetStripeReader(process.env.REACT_APP_STRIPE_PAYMENT_READER_ID);
            } catch(error) {
                console.log(error)
                setButtonText(BUTTON_DEFAULT_TEXT);
                showError("Payment could not be cancelled - please contact a Bedrock Wireless Rep")
            }
        }
    }

    function renderReservations() {
        if(renderComplete) {
            return (
                <View style={{paddingTop:"50px",flex:1,flexDirection:"row",justifyContent:"center"}}>
                    <View style={{width:"400px"}}>
                        <Checkmark size='xxlarge' color="#240058"/>
                    </View>
                </View>
            )
        } else {
            return (
                <View>
                    <PageHeader>
                        {[1,2].includes((new Date()).getMonth()) ? "IBS: $1 First Month Reservation" : "The Build Show: $1 First Month Reservation"}
                    </PageHeader>
                    <View style={{flex:1,flexDirection:"row",justifyContent:"center"}}>
                        <form className="info" onSubmit={e =>handleSubmitForPayment(e)}>
                            <View style={{flex:1, flexDirection:"column"}}>
                                <View style={{flex:1, flexDirection:"row"}}>
                                    <View style={{flex:1,flexDirection:"row"}}>
                                        <FormGroup controlId="fullName" bsSize="large" style={{width:"100%"}}>
                                            <ControlLabel>Full Name</ControlLabel>
                                            <FormControl
                                                autoFocus
                                                value={fullName}
                                                onChange={e => setFullName(e.target.value)}
                                            />
                                        </FormGroup>
                                    </View>
                                    {/* <FormGroup controlId="lastName" bsSize="large" style={{paddingLeft:"50px"}}>
                                        <ControlLabel>Last Name</ControlLabel>
                                        <FormControl
                                            value={lastName}
                                            onChange={e => setLastName(e.target.value)}
                                        />
                                    </FormGroup> */}
                                    <View style={{flex:1, flexDirection:"row",paddingLeft:"40px"}}>
                                        <FormGroup controlId="companyName" bsSize="large" style={{width:"100%"}}>
                                            <ControlLabel>Company Name</ControlLabel>
                                            <FormControl
                                                value={companyName}
                                                onChange={e => setCompanyName(e.target.value)}
                                            />
                                        </FormGroup>
                                    </View>
                                </View>
                                <View style={{flex:1, flexDirection:"row"}}>
                                    <View style={{flex:1, flexDirection:"row"}}>
                                        <FormGroup controlId="phone" bsSize="large" style={{width:"100%"}}>
                                            <ControlLabel>Phone Number</ControlLabel>
                                            <FormControl
                                                value={phone}
                                                type="tel"
                                                onChange={e => setPhone(e.target.value)}
                                            />
                                        </FormGroup>
                                    </View>
                                    <View style={{flex:1,flexDirection:"row",paddingLeft:"40px"}}>
                                        <FormGroup controlId="email" bsSize="large" style={{width:"100%"}}>
                                            <ControlLabel>
                                                Email
                                            </ControlLabel>
                                            <FormControl
                                                value={email}
                                                type="email"
                                                onFocus={e => setEmailActivated(true)}
                                                onChange={e => setEmail(e.target.value)}
                                            />
                                        </FormGroup>
                                        
                                    </View>
                                    {/* {emailActivated && (    
                                        // <Fade right when={emailActivated}>
                                        //     <ControlLabel style={{paddingLeft:"20px",paddingTop:"38px",whiteSpace:"nowrap"}}>
                                        //         <Glyphicon glyph="arrow-left" style={{paddingRight:"10px"}} />
                                        //         We'll send your receipt here
                                        //     </ControlLabel>
                                        // </Fade>
                                    )} */}
                                </View>
                                <View style={{flex:1,flexDirection:"row",paddingBottom:"0px"}}>
                                    <View style={{flex:1,flexDirection:"row"}}>
                                    </View>
                                    <View style={{flex:1,flexDirection:"row",paddingLeft:"40px"}}>
                                        <Fade bottom when={emailActivated}>
                                            {/* <Glyphicon glyph="arrow-up" style={{paddingRight:"10px",color:"#297ACC"}} /> */}
                                            <b>We'll send your receipt here</b>
                                        </Fade>
                                    </View>
                                </View>
                                <View style={{flex:1,paddingBottom:"10px",flexDirection:"row"}}>
                                    <FormGroup>
                                        <ControlLabel style={{paddingBottom:"10px"}}>
                                            How soon do you think you'll need cameras?
                                        </ControlLabel>
                                        <View style={{flex:1,flexDirection:"row"}}>
                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                <CustomDateTimePicker
                                                    format={"MMM d"}
                                                    value={dateSelected}
                                                    onChange={setDateSelected}
                                                    minDate={addDaysToDate(new Date(),7)}
                                                    InputProps={{disableUnderline:true}}
                                                    disabled={ignoreDateSelection}
                                                />
                                            </MuiPickersUtilsProvider>
                                            <View style={{paddingLeft:"50px"}}>
                                                <Checkbox checked={ignoreDateSelection} onChange={e => setIgnareDateSelection(!ignoreDateSelection)}>
                                                    <div style={{paddingLeft:"20px"}}>Skip</div>
                                                </Checkbox>
                                            </View>
                                        </View>
                                    </FormGroup>
                                </View>
                                <View style={{flex:1, flexDirection:"row",paddingBottom:"30px",paddingLeft:"10px"}}>
                                    <Checkbox checked={checkedIsChecked} onChange={e => setCheckedIsChecked(!checkedIsChecked)}>
                                    <div style={{paddingLeft:"20px"}}>You are agreeing to pay a no-obligation $1 reservation fee that is redeemable for 1 month of Bedrock Wireless' service.</div>
                                    </Checkbox>
                                </View>
                                <View style={{flex:1, flexDirection:"row",justifyContent:"center"}}>
                                    <Button
                                        block
                                        type="submit"
                                        bsSize="large"
                                        disabled={!checkedIsChecked || phone===""|| email===""||fullName===""}
                                    >
                                        {buttonText}
                                    </Button>
                                </View>
                            </View>
                        </form>
                    </View>
                    {cancellable && (
                        <View style={{paddingTop:"10px",flex:1,flexDirection:"row",justifyContent:"center"}}>
                            <View style={{width:"200px"}}>
                                <Button
                                    block
                                    bsSize="large"
                                    bsStyle="danger"
                                    disabled={!cancellable}
                                    onClick={(e) => cancelPayment(e)}
                                >
                                    Cancel Transaction
                                </Button>
                            </View>
                        </View>
                    )}
                </View>
            );
        }
    }

    function renderBadAccess() {
        props.history.push(`/dashboard`)
    }

    return (
        <div className="Account" style={{paddingTop:"10px"}}>
            {getUser().role==="PAYMENTS" || getUser().role==="ADMIN" ? renderReservations() : renderBadAccess()}
        </div>
    )
}